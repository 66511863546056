var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { margin: "5px 0" } },
        [
          _vm.user.roles.find(function (role) {
            return ["Admin"].includes(role.name)
          })
            ? _c(
                "router-link",
                {
                  staticClass: "el-button el-button--primary",
                  class: { "is-disabled": _vm.isOffline },
                  attrs: { to: _vm.isOnline ? "/leaves/add" : "#" },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus" }),
                  _vm._v(" Add Leave"),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "left",
              label: _vm.$t("Warehouse.Action"),
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _vm.user.roles.find(function (role) {
                          return ["Admin"].includes(role.name)
                        })
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { padding: "5px" },
                                attrs: {
                                  type: "warning",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editLeave(scope.row.uuid)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Order.Edit")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              padding: "5px",
                              "margin-left": "0",
                              "margin-top": "5px",
                            },
                            attrs: {
                              type: "success",
                              icon: "el-icon-user-solid",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.leaveForStaff(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("Staff")]
                        ),
                        _vm._v(" "),
                        _vm.user.roles.find(function (role) {
                          return ["Admin"].includes(role.name)
                        }) && scope.row.id > 5
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  padding: "5px",
                                  "margin-left": "0",
                                  "margin-top": "5px",
                                },
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-delete",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteleave(scope.row.uuid)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "新員工請假" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.employment_condition === "any"
                      ? _c("div", [_vm._v("入職即可請假")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.employment_condition === "confirmed"
                      ? _c("div", [_vm._v("轉正後才可請假")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.employment_condition === "custom"
                      ? _c("div", [
                          _vm._v(
                            "自定義: 入職 " +
                              _vm._s(scope.row.employment_months) +
                              " 月"
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "請假方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.unit === "full-day"
                      ? _c("div", [_vm._v("按天請")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unit === "half-day"
                      ? _c("div", [_vm._v("按半天請")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unit === "hour"
                      ? _c("div", [_vm._v("按小時請")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "假期限額" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.unit === "full-day" && scope.row.quota > 0
                      ? _c("div", [_vm._v(_vm._s(scope.row.quota) + " 日")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unit === "half-day" && scope.row.quota > 0
                      ? _c("div", [_vm._v(_vm._s(scope.row.quota) + " 日")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unit === "hour" && scope.row.quota > 0
                      ? _c("div", [_vm._v(_vm._s(scope.row.quota) + " 小時")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.quota == "0"
                      ? _c("div", [_vm._v("不設假期餘額")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Default Role", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.roles
                            .map(function (item) {
                              return item.name
                            })
                            .join(", ")
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }