var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c("div", { staticStyle: { margin: "10px 0" } }, [
            _vm._v(_vm._s(_vm.leaveUser) + ", 你可以申請以下的假期:"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isOffline },
              on: { click: _vm.applyLeave },
            },
            [_vm._v("申請")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名稱" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已使用" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "已使用" +
                          _vm._s(scope.row.used) +
                          _vm._s(scope.row.unit === "hour" ? "小時" : "日") +
                          " " +
                          _vm._s(
                            scope.row.quota > 0
                              ? " / 總共" +
                                  scope.row.quota +
                                  (scope.row.unit === "hour" ? "小時" : "日")
                              : ""
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申請單位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.unit === "full-day"
                      ? _c("span", [_vm._v("一日")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unit === "half-day"
                      ? _c("span", [_vm._v("半日")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unit === "hour"
                      ? _c("span", [_vm._v("小時")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "重設周期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.refill_period === "year"
                      ? _c("span", [_vm._v("每年第一個月")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.refill_period === "month"
                      ? _c("span", [_vm._v("每月第一日")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.refill_period === "week"
                      ? _c("span", [_vm._v("每星期第一日")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "動作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", icon: "el-icon-time" },
                            on: {
                              click: function ($event) {
                                return _vm.leaveHistory(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("記錄")]
                        ),
                        _vm._v(" "),
                        scope.row.ot_count == "1"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "warning",
                                  icon: "el-icon-time",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.otHistory(scope.row.uuid)
                                  },
                                },
                              },
                              [_vm._v("OT記錄")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }