var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: _vm.device !== "mobile",
                "label-position": _vm.device === "mobile" ? "top" : "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.query($event)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Filter Date:", prop: "dateRange" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": " - ",
                      "start-placeholder": _vm.$t(
                        "RawMaterialScheduledPrice.StartDate"
                      ),
                      "end-placeholder": _vm.$t(
                        "RawMaterialScheduledPrice.EndDate"
                      ),
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Order.User"), prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("route.Users"),
                        clearable: "",
                        filterable: "",
                        "filter-method": _vm.userFilter,
                      },
                      model: {
                        value: _vm.listQuery.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "user_id", $$v)
                        },
                        expression: "listQuery.user_id",
                      },
                    },
                    _vm._l(_vm.userOptions, function (user) {
                      return _c("el-option", {
                        key: user.name,
                        attrs: { label: user.name, value: user.uuid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("Order.Leave"), prop: "leave_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("Order.Leave"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.listQuery.leave_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "leave_id", $$v)
                        },
                        expression: "listQuery.leave_id",
                      },
                    },
                    _vm._l(_vm.leaveOptions, function (leave) {
                      return _c("el-option", {
                        key: leave.name,
                        attrs: { label: leave.name, value: leave.uuid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-form-item__content",
                  staticStyle: {
                    "margin-top": "10px",
                    "line-height": "20px",
                    "margin-bottom": "10px",
                    display: "block",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": "pending" },
                      model: {
                        value: _vm.listQuery.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "status", $$v)
                        },
                        expression: "listQuery.status",
                      },
                    },
                    [_vm._v("Pending Status")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.query } },
                    [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "動作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        scope.row.status === "draft" ||
                        scope.row.status === "pending"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { padding: "5px" },
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.approveLeave(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Approve")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === "draft" ||
                        scope.row.status === "pending"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  padding: "5px",
                                  "margin-left": "0",
                                  "margin-top": "5px",
                                },
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rejectLeave(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Reject")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.leave.refill_period !== "week" &&
                    _vm.user.roles.find(function (role) {
                      return ["Operation Manager", "Admin"].includes(role.name)
                    })
                      ? _c(
                          "span",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "el-button el-button--text",
                                attrs: {
                                  to:
                                    "/users/" +
                                    scope.row.user.uuid +
                                    "/leaves/summary/" +
                                    scope.row.leave.uuid,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.user.name))]
                            ),
                          ],
                          1
                        )
                      : _c("span", [_vm._v(_vm._s(scope.row.user.name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.getDatePeriod(scope.row)))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "年期類別" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.leave.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "日數" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.total))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Status") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.status))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Attachment" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.attachments,
                    function (attachment, key) {
                      return _c("div", {
                        key: key,
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getAttachmentURL(attachment, key)
                          ),
                        },
                      })
                    }
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }