var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("label", [_vm._v(_vm._s(_vm.$t("Order.Date")) + ": ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": " - ",
          "start-placeholder": "開始日期",
          "end-placeholder": "結束日期",
          "value-format": "yyyy-MM-dd",
        },
        model: {
          value: _vm.defaultDateRange,
          callback: function ($$v) {
            _vm.defaultDateRange = $$v
          },
          expression: "defaultDateRange",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.query } },
        [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c("div", { staticStyle: { margin: "10px 0" } }, [
            _vm._v("以下為" + _vm._s(_vm.leaveUser) + " 的OT 時間:"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Duty日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.fromTime
                            ? "From: " + scope.row.fromTime
                            : ""
                        ) +
                        "\n        "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.toTime ? "To: " + scope.row.toTime : ""
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.store ? scope.row.store.name : "") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Effective OT Clock Out Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.effectiveClockOutTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "OT hour" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.ot_hour) +
                          " hour" +
                          _vm._s(scope.row.ot_hour > 1 ? "s" : "") +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }