var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "max-width": "500px" },
          attrs: {
            model: _vm.history,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.history.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.history, "id", $$v)
                  },
                  expression: "history.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "假期種類", prop: "leave_id" } },
            [
              _c(
                "div",
                { staticClass: "d-flex font" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "請選擇..." },
                      on: { change: _vm.changeUnit },
                      model: {
                        value: _vm.history.leave_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.history, "leave_id", $$v)
                        },
                        expression: "history.leave_id",
                      },
                    },
                    _vm._l(_vm.leaves, function (leave) {
                      return _c("el-option", {
                        key: leave.value,
                        attrs: { label: leave.name, value: leave.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "gary-text",
                  staticStyle: { "margin-left": "10px" },
                },
                [_vm._v(_vm._s(_vm.remainHoildayDisplay))]
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "開始時間" } }, [
            _c(
              "div",
              { staticClass: "d-flex font" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    clearable: false,
                  },
                  on: { change: _vm.calcTotal },
                  model: {
                    value: _vm.history.datefrom,
                    callback: function ($$v) {
                      _vm.$set(_vm.history, "datefrom", $$v)
                    },
                    expression: "history.datefrom",
                  },
                }),
                _vm._v(" "),
                _vm.leaveUnit === "hour"
                  ? _c("el-time-select", {
                      staticStyle: { width: "150px" },
                      attrs: {
                        "picker-options": {
                          start: "00:00",
                          step: "00:15",
                          end: "23:59",
                        },
                        clearable: false,
                        placeholder: "請選擇時間",
                      },
                      on: { change: _vm.calcTotal },
                      model: {
                        value: _vm.history.timefrom,
                        callback: function ($$v) {
                          _vm.$set(_vm.history, "timefrom", $$v)
                        },
                        expression: "history.timefrom",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.leaveUnit === "half-day"
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { width: "150px" },
                        on: { change: _vm.calcTotal },
                        model: {
                          value: _vm.history.periodfrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.history, "periodfrom", $$v)
                          },
                          expression: "history.periodfrom",
                        },
                      },
                      _vm._l(_vm.periods, function (period) {
                        return _c("el-option", {
                          key: period.value,
                          attrs: { label: period.name, value: period.value },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "結束時間" } }, [
            _c(
              "div",
              { staticClass: "d-flex font" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    clearable: false,
                  },
                  on: { change: _vm.calcTotal },
                  model: {
                    value: _vm.history.dateto,
                    callback: function ($$v) {
                      _vm.$set(_vm.history, "dateto", $$v)
                    },
                    expression: "history.dateto",
                  },
                }),
                _vm._v(" "),
                _vm.leaveUnit === "hour"
                  ? _c("el-time-select", {
                      staticStyle: { width: "150px" },
                      attrs: {
                        "picker-options": {
                          start: "00:00",
                          step: "00:15",
                          end: "23:59",
                        },
                        clearable: false,
                        placeholder: "請選擇時間",
                      },
                      on: { change: _vm.calcTotal },
                      model: {
                        value: _vm.history.timeto,
                        callback: function ($$v) {
                          _vm.$set(_vm.history, "timeto", $$v)
                        },
                        expression: "history.timeto",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.leaveUnit === "half-day"
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { width: "150px" },
                        on: { change: _vm.calcTotal },
                        model: {
                          value: _vm.history.periodto,
                          callback: function ($$v) {
                            _vm.$set(_vm.history, "periodto", $$v)
                          },
                          expression: "history.periodto",
                        },
                      },
                      _vm._l(_vm.periods, function (period) {
                        return _c("el-option", {
                          key: period.value,
                          attrs: { label: period.name, value: period.value },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.leaveUnitDisplay, prop: "total" } },
            [
              _c("el-input", {
                attrs: {
                  type: "number",
                  step: _vm.leaveUnitStep,
                  min: _vm.leaveMin,
                  max: _vm.leaveMax,
                  readonly: "",
                  required: "",
                },
                model: {
                  value: _vm.history.total,
                  callback: function ($$v) {
                    _vm.$set(_vm.history, "total", $$v)
                  },
                  expression: "history.total",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "請假原因" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.history.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.history, "remark", $$v)
                  },
                  expression: "history.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "附件" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.formUpload.action,
                    data: _vm.formUpload.data,
                    "file-list": _vm.history.attachment,
                    accept: "*,image/jpeg,image/png,application/pdf",
                    "show-file-list": true,
                    multiple: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "on-remove": _vm.onFileRemove,
                    "before-upload": _vm.beforeUpload,
                    "on-change": _vm.onFileChange,
                    "on-success": _vm.onUploaded,
                    "on-error": _vm.onError,
                    "auto-upload": _vm.uploadValid,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("選擇文件")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: { "margin-top": "0", "line-height": "20px" },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        "只能上傳JPG/PNG/PDF，最多上傳3個文件, 每個不能超過" +
                          _vm._s(_vm.uploadFileLimit) +
                          "MB"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "狀態" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "請選擇...", disabled: "" },
                  model: {
                    value: _vm.history.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.history, "status", $$v)
                    },
                    expression: "history.status",
                  },
                },
                _vm._l(_vm.statuss, function (status) {
                  return _c("el-option", {
                    key: status.value,
                    attrs: { label: status.name, value: status.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-success" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.$t("Order.Cancel"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }