"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _user = require("@/api/user");

var _leave = require("@/api/leave");

var _elementUi = require("element-ui");

var _errorParse = require("@/utils/error-parse");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd'
var _default = {
  components: {
    Pagination: _Pagination.default
  },

  data() {
    return {
      list: null,
      listLoading: false,
      validApprovalDate: null,
      userOptions: [],
      leaveOptions: [],
      leaves: [],
      listQuery: {
        fromTime: null,
        toTime: null,
        user_id: null,
        leave_id: null,
        status: null,
        listAll: true,
        page: 1,
        limit: 10
      },
      total: 0
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)([// 'name',
    'user']),
    dateRange: {
      get() {
        return [this.listQuery.fromTime ? this.listQuery.fromTime : '', this.listQuery.toTime ? this.listQuery.toTime : '']; // return this.supplierQuery.fromTime //weekly
      },

      set(range) {
        if (range != null) {
          this.listQuery.fromTime = range[0];
          this.listQuery.toTime = range[1];
        } else {
          this.listQuery.fromTime = null;
          this.listQuery.toTime = null;
        }
      }

    }
  },

  async created() {
    this.fetchData();
    this.fetchUsers();
    this.fetchLeaves();
  },

  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _leave.getHistory)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total; // console.log(response)
      }).finally(() => {
        this.listLoading = false;
      }); // var currentTime = new Date()
      // console.log(currentTime)
      // this.validApprovalDate
    },

    async fetchUsers() {
      (0, _user.getUsers)({
        limit: 999,
        active: 'true'
      }).then(response => {
        this.users = response.data;
        this.userOptions = response.data;
      });
    },

    async fetchLeaves() {
      (0, _leave.getLeaves)({
        limit: 999
      }).then(response => {
        this.leaves = response.data;
        this.leaveOptions = response.data;
      });
    },

    userFilter(val) {
      this.value = val;

      if (val) {
        this.userOptions = this.users.filter(item => {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },

    getDatePeriod(valObj) {
      if (valObj.date_from === valObj.date_to && valObj.periodfrom === 'AM' && valObj.periodto === 'PM') {
        return valObj.date_from;
      }

      return valObj.date_from + ' ' + valObj.periodfrom + ' - ' + valObj.date_to + ' ' + valObj.periodto;
    },

    approveLeave(history) {
      this.listLoading = true;
      (0, _leave.leaveApprove)(history).then(() => {
        (0, _elementUi.Message)({
          message: 'Leave Approve submitted.',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        }); // console.log(err)
      }).finally(() => {
        this.listLoading = false;
      }); // this.$router.push(`/leave-history/approve/${id}`)
    },

    rejectLeave(history) {
      this.listLoading = true;
      (0, _leave.leaveReject)(history).then(() => {
        (0, _elementUi.Message)({
          message: 'Leave Approve submitted.',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        }); // console.log(err)
      }).finally(() => {
        this.listLoading = false;
      }); // this.$router.push(`/leave-history/reject/${id}`)
    },

    deleteleave(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete Leave?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _leave.deleteLeave)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Leave deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    getAttachmentURL(attachment, key) {
      return '<a href="' + attachment.url + '" target="_blank"><i class="el-icon-document"></i> Attachment ' + (key + 1) + '</a>';
    },

    query() {
      const param = {};

      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          let current_datetime = this.listQuery[key];

          if (typeof current_datetime === 'string') {
            current_datetime = new Date(current_datetime);
          }

          param[key] = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate();
        } else {
          param[key] = this.listQuery[key];
        }
      }

      this.$router.push({
        query: { ...this.listQuery,
          ...param
        }
      });
      this.fetchData();
    }

  }
};
exports.default = _default;