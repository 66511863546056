"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _leave = require("@/api/leave");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0,
      leaveUser: null
    };
  },

  computed: { ...(0, _vuex.mapGetters)([// 'name',
    'user'])
  },

  async created() {
    let user_uuid = this.user.uuid;

    if (this.$route.params.user_id) {
      user_uuid = this.$route.params.user_id;
    }

    (0, _user.getUser)(user_uuid).then(leaveUser => {
      this.leaveUser = leaveUser.data.name;
    });
    this.listLoading = true;
    (0, _leave.getUserLeave)(user_uuid).then(response => {
      const data = response.data;
      this.list = [];

      for (let i = 0; i < data.length; i++) {
        const leave = data[i].leave;
        leave.used = data[i].used;
        this.list.push(leave);
      }
    }).finally(() => {
      this.listLoading = false;
    });
  },

  methods: {
    applyLeave() {
      if (this.$route.params.user_id) {
        this.$router.push(`/users/${this.$route.params.user_id}/leaves/apply/add`);
      } else {
        this.$router.push(`/leaves/apply/add`);
      }
    },

    leaveHistory(uuid) {
      if (this.$route.params.user_id) {
        this.$router.push(`/users/${this.$route.params.user_id}/leaves/summary/` + uuid);
      } else {
        this.$router.push(`/leaves/summary/` + uuid);
      }
    },

    otHistory(uuid) {
      if (this.$route.params.user_id) {
        this.$router.push(`/users/${this.$route.params.user_id}/leaves/ot/` + uuid);
      } else {
        this.$router.push(`/leaves/summary/` + uuid);
      }
    }

  }
};
exports.default = _default;