var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [_c("el-form-item", { attrs: { label: _vm.leaveName } })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.leaveData,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c("table", { staticClass: "table table-striped table-bordered" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { align: "left" } }, [
                  _c("label", { staticClass: "form-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectAll,
                          expression: "selectAll",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.selectAll)
                          ? _vm._i(_vm.selectAll, null) > -1
                          : _vm.selectAll,
                      },
                      on: {
                        click: _vm.select,
                        change: function ($event) {
                          var $$a = _vm.selectAll,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectAll = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectAll = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("i", { staticClass: "form-icon" }),
                  ]),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { align: "left" } }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", { attrs: { align: "left" } }, [_vm._v("Roles")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.list, function (i) {
                return _c("tr", { key: i.uuid }, [
                  _c("td", { attrs: { width: "55" } }, [
                    _c("label", { staticClass: "form-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedUser,
                            expression: "selectedUser",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: i.id,
                          checked: Array.isArray(_vm.selectedUser)
                            ? _vm._i(_vm.selectedUser, i.id) > -1
                            : _vm.selectedUser,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.selectedUser,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = i.id,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.selectedUser = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectedUser = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectedUser = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "form-icon" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("\n            " + _vm._s(i.name) + "\n          "),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          i.roles
                            .map(function (item) {
                              return item.name
                            })
                            .join(", ")
                        ) +
                        "\n          "
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isOffline },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-circle-close" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }