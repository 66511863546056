"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _leave = require("@/api/leave");

var _elementUi = require("element-ui");

var _errorParse = require("@/utils/error-parse");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        uuid: this.$route.params.id
      },
      total: 0,
      selectedUser: [],
      selectAll: false,
      leaveData: null,
      leaveName: null
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },

  async created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.listLoading = true;
      (0, _leave.getLeave)(this.$route.params.id).then(response => {
        this.leaveData = response.data;
        this.leaveName = this.leaveData.name;
        (0, _leave.getLeaveUsers)(this.listQuery).then(response => {
          this.list = response.data;

          for (const i in this.list) {
            if (this.list[i].leave) {
              this.selectedUser.push(this.list[i].id);
            }
          } // this.total = response.meta.total

        }).finally(() => {
          this.listLoading = false;
        });
      });
    },

    select() {
      this.selectedUser = [];

      if (!this.selectAll) {
        for (const i in this.list) {
          this.selectedUser.push(this.list[i].id);
        } // console.log(this.selectedUser)

      }
    },

    onCancel() {
      this.$router.push(`/leaves`);
    },

    onSubmit() {
      this.loading = true;
      (0, _leave.editLeaveUsers)(this.leaveData, this.selectedUser).then(() => {
        (0, _elementUi.Message)({
          message: 'Record updated.',
          type: 'success',
          duration: 5 * 1000
        });
        this.$router.push(`/leaves`);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        }); // console.log(err)

        this.loading = false;
      });
    }

  }
};
exports.default = _default;